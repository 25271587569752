<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-alert-circle"
    :width="$attrs.width"
    :height="$attrs.height"
    viewBox="0 0 24 24"
    stroke-width="2"
    :stroke="$attrs.colors && $attrs.colors.header ? $attrs.colors.header.bg : '#D32F2F'"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
  </svg>
</template>

<script>
export default {}
</script>
