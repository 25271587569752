import Vue from 'vue'
import store, {API} from './store/vuex'
import App from './App.vue'
import Chat from './index.js'
import {io} from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

const socket = io(process.env.VUE_APP_WEBCHAT_SERVICE_URL ?? 'http://localhost:3000')
Vue.use(VueSocketIOExt, socket, {store})
Vue.use(Chat)
Vue.use(VuePlyr)
// eslint-disable-next-line
new Vue({
  el: '#app',
  store,
  sockets: {
    connect() {
      this.$store.dispatch('INIT', {})
    }
  },
  render: (h) => h(App)
})
