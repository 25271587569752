<template>
  <div style="position: relative;">
    <ChatWindow
      :message-list="messageList"
      :on-user-input-submit="onMessageWasSent"
      :participants="participants"
      :title="chatWindowTitle"
      :is-open="openChat"
      :show-emoji="showEmoji"
      :show-file="false"
      :show-header="showHeader"
      :placeholder="placeholder"
      :show-typing-indicator="showTypingIndicator"
      :colors="colors"
      :always-scroll-to-bottom="toBottom"
      :message-styling="messageStyling"
      :show-form="!user.id.length"
      @close="close"
      @scrollToTop="handleScroll"
      @onType="$emit('onType')"
      @edit="$emit('edit', $event)"
      @remove="$emit('remove', $event)"
    >
      <template v-slot:header>
        <slot name="header"> </slot>
      </template>
      <template v-slot:user-avatar="scopedProps">
        <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me">
        </slot>
      </template>
    </ChatWindow>
  </div>
</template>

<script>
import store from './store/'
import ChatWindow from './ChatWindow.vue'
import {mapActions, mapGetters} from 'vuex'
import CloseIcon from './assets/close-icon.png'
import OpenIcon from './assets/logo-no-bg.svg'

export default {
  components: {
    ChatWindow
  },
  props: {
    icons: {
      type: Object,
      default: function () {
        return {
          open: {
            img: OpenIcon,
            name: 'default'
          },
          close: {
            img: CloseIcon,
            name: 'default'
          }
        }
      }
    },
    showEmoji: {
      type: Boolean,
      default: true
    },
    showEdition: {
      type: Boolean,
      default: false
    },
    showDeletion: {
      type: Boolean,
      default: false
    },
    showLauncher: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    /*     participants: {
      type: Array,
      required: true
    }, */
    title: {
      type: String,
      default: () => ''
    },
    titleImageUrl: {
      type: String,
      default: () => ''
    },
    /*     onMessageWasSent: {
      type: Function,
      required: true
    }, */
    newMessagesCount: {
      type: Number,
      default: () => 0
    },
    placeholder: {
      type: String,
      default: 'Escribe un mensaje...'
    },
    showTypingIndicator: {
      type: String,
      default: () => ''
    },
    alwaysScrollToBottom: {
      type: Boolean,
      default: () => false
    },
    messageStyling: {
      type: Boolean,
      default: () => false
    },
    disableUserListToggle: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openChat: false,
    toBottom: true
  }),
  computed: {
    ...mapGetters({
      messageList: 'MESSAGE_LIST',
      colors: 'COLORS',
      participants: 'PARTICIPANTS',
      user: 'USER'
    }),
    chatWindowTitle() {
      if (this.title !== '') return this.title

      if (this.participants.length === 0) return 'Tú'
      // if (this.participants.length > 1) return 'Tú, ' + this.participants[0].name + ' & otros'

      return 'Tú & ' + this.participants[0].name
    }
  },
  watch: {
    $props: {
      deep: true,
      immediate: true,
      handler(props) {
        for (const prop in props) {
          store.setState(prop, props[prop])
        }
      }
    }
  },
  mounted() {
    const onmessage = (e) => {
      this.openChat = e.data.open
    }
    window.onmessage = onmessage
  },
  methods: {
    ...mapActions({sendMessage: 'SEND_MESSAGE', loadMessages: 'LOAD_MESSAGES'}),
    openAndFocus() {
      this.open()
      this.$root.$emit('focusUserInput')
    },
    open() {
      this.openChat = true
      this.newMessagesCount = 0
    },
    close() {
      window.top.postMessage('close', '*')
      this.openChat = false
    },
    onMessageWasSent(message) {
      this.sendMessage(message)
      // this.messageList = [...this.messageList, Object.assign({}, message, {id: Math.random()})]
    },
    handleScroll({event, scrollList}) {
      this.loadMessages({event, scrollList})
    }
  }
}
</script>

<style>
.chatLink.chatLink-url {
  color: var(--link-text);
}
</style>
