<template>
  <div class="container">
    <div class="c2">
      <form class="signup" @submit="sendForm">
        <h1 class="signup1" :style="{ color: colors.userInput.text }">Registro</h1>
        <div class="form-input">
          <input
            v-model="name"
            name="username"
            type="text"
            placeholder="Nombre"
            class="username"
            :class="{ inputError: errorName }"
            @change="resetError('name')"
          />
          <div v-if="errorName" class="error">
            <IconError width="20" height="20" />
          </div>
          <small v-if="errorName" class="text-error">Ingrese un nombre válido</small>
        </div>
        <div class="form-input">
          <input
            v-model="email"
            name="email"
            type="text"
            placeholder="Email"
            class="username"
            :class="{ inputError: errorEmail }"
            @change="resetError('email')"
          />
          <div v-if="errorEmail" class="error">
            <IconError width="20" height="20" />
          </div>
          <small v-if="errorEmail" class="text-error">Ingrese un email válido</small>
        </div>
        <button class="btn" :style="{ background: colors.header.bg }">Unirme</button>
      </form>
    </div>
  </div>
</template>

<script>
import IconError from './components/IconError.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    IconError
  },
  props: {
    colors: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    name: '',
    email: '',
    errorName: false,
    errorEmail: false
  }),
  methods: {
    ...mapActions({ init: 'INIT' }),
    sendForm(e) {
      e.preventDefault()
      const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      const nameRegex = /^([a-zA-ZáéíóúÁÉÍÓÚñÑ]{2,}[ ]?){1,}$/
      if (!nameRegex.test(this.name)) this.errorName = true
      if (!emailRegex.test(this.email)) this.errorEmail = true
      if (this.errorName || this.errorEmail) return
      this.init({ name: this.name, email: this.email })
    },
    resetError(type) {
      if (type === 'name') this.errorName = false
      else if (type === 'email') this.errorEmail = false
    }
  }
}
</script>

<style scoped>
body {
  background: #faf3ec;
  font-family: 'Roboto';
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #748194;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #748194;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #748194;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #748194;
}
.container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.c1 {
  box-shadow: 0 0 10px grey;
  background-color: white;
  width: 300px;
  height: 500px;
  display: inline-block;
  border-radius: 15px 15px 15px 15px;
}

.c11 {
  background-image: url('https://i.pinimg.com/736x/b8/09/22/b80922f6ea2daaf36a6627378662803b--deck-of-cards-phone-wallpapers.jpg');
  background-size: 300px 400px;
  background-repeat: no-repeat;
  background-color: white;
  width: 300px;
  height: 400px;
  display: inline-block;
  position: absolute;
  z-index: 4;
  border-radius: 15px 15px 200px 200px;
}
#left,
#right {
  color: white;
  display: inline-block;
  width: 146px;
  height: 500px;
  background-color: white;
  cursor: pointer;
}
#left {
  border-radius: 15px 0px 0px 15px;
}
#right {
  border-radius: 15px 15px 15px 0px;
}
.s1class {
  color: #748194;
  position: absolute;
  bottom: 0;
  left: 63%;
  margin-left: -50%;
}
.s1class span,
.s2class span {
  display: block;
}
.su {
  font-size: 20px;
}
.s2class {
  color: #748194;
  position: absolute;
  bottom: 0;
  right: 63%;
  margin-right: -50%;
}
.mainhead {
  color: white;
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}
.mainp {
  color: white;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}
.c2 {
  background-color: white;
  width: 300px;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}
.form-input {
  margin: 0 35px 30px;
  position: relative;
}
.inputError {
  border: 1px solid #d32f2f !important;
}
.text-error {
  color: #d32f2f;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
.username {
  font-weight: bold;
  width: 200px;
  height: 35px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: #f6f7f9;
  color: #748194;
  font-size: 14px;
}
.error {
  position: absolute;
  right: 0;
  margin-right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}
.btn {
  font-weight: bold;
  width: 230px;
  margin: 0 35px 3em;
  height: 45px;
  padding: 6px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  font-size: 14px;
}
.btn:hover {
  cursor: pointer;
}
.signup1 {
  font-size: 20px;
  margin: 2em 0;
  text-align: center;
}
a {
  text-decoration: none;
}
.signup2 {
  color: #748194;
  font-size: 20px;
  text-align: center;
}
.signup {
  display: initial;
}
.signin {
  display: initial;
}
</style>
