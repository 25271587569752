import Vue from 'vue'
import Vuex from 'vuex'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import availableColors from '../colors'

Vue.use(Vuex)
export const API = process.env.VUE_APP_OMNICHANNEL_URL
export const SHOW_FORM = process.env.VUE_APP_SHOW_FORM
export const COLOR = process.env.VUE_APP_COLOR ?? 'blue'

// document.documentElement.style.setProperty()
const store = new Vuex.Store({
  state: {
    messages: [],
    participants: [],
    options: {
      instance: '',
      user: { id: '', name: '', email: '' },
      forMe: 'me',
      color: COLOR
    },
    loading: false,
    paginate: {
      currHeight: 0,
      skip: 0,
      limit: 10,
      allFetched: false
    }
  },
  mutations: {
    SOCKET_MESSAGE(state, message) {
      if (message.hasOwnProperty('body')) {
        if (message.body.hasOwnProperty('attachment')) {
          message.type = 'file'
          message.data = {
            file: { ...message.body.attachment }
          }
        } else {
          message.data = { ...message.body }
          message.type = 'text'
        }
        if (state.participants.length) message.author = state.participants[0].id
        else message.author = 'other'
        delete message.body
      }
      message.id = uuid()
      message.data.meta = DateTime.now().toFormat('dd/MM/yyyy hh:mm')
      window.top.postMessage('newMessage', '*')
      state.messages.push(message)
    },
    SOCKET_TOKEN(state, message) {
      localStorage.setItem('ACCESS_TOKEN', message.token)
    },
    SET_USER(state, { id, name, email }) {
      state.options.user = {
        id,
        name,
        email
      }
    },
    ALL_FETCHED(state, fetched) {
      state.paginate.allFetched = fetched
    },
    SET_INSTANCE(state, instance) {
      state.options.instance = instance
    },
    SET_COLOR(state, color) {
      const root = document.documentElement
      Object.keys(availableColors[color]).forEach((element) =>
        Object.keys(availableColors[color][element]).forEach((key) =>
          root.style.setProperty(`--${element}-${key}`, availableColors[color][element][key])
        )
      )
      root.style.setProperty(
        '--plyr-audio-controls-background',
        availableColors[color].receivedMessage.bg
      )
      state.options.color = color
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_INITIAL_PARTICIPANTS(state, { agent, organization }) {
      state.participants = [
        ...agent.map((ag) => ({
          id: ag._id,
          name: ag.name,
          imageUrl: ag.picture ?? 'http://cdn.onlinewebfonts.com/svg/img_83486.png'
        })),
        {
          id: organization._id,
          name: organization.friendly_name,
          imageUrl: organization.picture
        }
      ]
    },
    SET_MESSAGES(state, messages) {
      const participants = {}
      if (!Array.isArray(messages)) return
      const msgs = messages.map((msg) => {
        if (msg.agent) participants[msg.agent._id] = msg.agent.name
        return {
          author:
            msg.origin === 'client'
              ? state.options.forMe
              : msg.origin === 'agent'
              ? msg.agent._id
              : msg.origin,
          type: msg.body.hasOwnProperty('attachment') ? 'file' : 'text',
          id: msg._id,
          data: {
            text: msg.body.text,
            file: msg.body.attachment,
            meta: DateTime.fromISO(msg.date).toFormat('dd/MM/yyyy hh:mm')
          }
        }
      })
      state.messages = state.messages.length < 5 ? msgs : msgs.concat(state.messages)
      Object.entries(participants).forEach(([id, name]) => {
        if (!state.participants.some((pa) => pa.id === id))
          state.participants = state.participants.concat({
            id,
            name,
            imageUrl: 'http://cdn.onlinewebfonts.com/svg/img_83486.png'
          })
      })
    },
    SET_PAGINATE(state, { currHeight, skip }) {
      state.paginate.currHeight = currHeight
      state.paginate.skip = skip
    }
  },
  actions: {
    SEND_MESSAGE({ commit, state }, payload) {
      if (!payload.hasOwnProperty('author')) {
        payload.author = state.options.forMe
        payload.type = 'text'
      }
      commit('SOCKET_MESSAGE', payload)
      this._vm.$socket.client.emit('message', {
        instance: state.options.instance,
        user: state.options.user,
        token: localStorage.getItem('ACCESS_TOKEN'),
        body: {
          ...payload.data,
          text: payload.data.text ?? payload.data.emoji
        }
      })
    },
    LOAD_MESSAGES({ commit, state }, { event, scrollList }) {
      const { skip, limit, allFetched } = state.paginate
      if (!state.loading && !allFetched) {
        commit('SET_LOADING', true)
        commit('SET_PAGINATE', {
          skip: skip + limit,
          currHeight: event?.target.scrollHeight ?? 0
        })
        fetch(`${API}/api/ticket/${state.options.user.id}/message?skip=${skip}&limit=${limit}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` }
        })
          .then((res) => {
            if (res.status === 401) throw new Error('Unauthorized')
            return res.json()
          })
          .then((res) => {
            commit('SET_LOADING', false)
            if (res.message.length) {
              commit('SET_INITIAL_PARTICIPANTS', res)
              commit('SET_MESSAGES', res.message.reverse())
            } else commit('ALL_FETCHED', true)
          })
          .catch(() => this.dispatch('SEND_MESSAGE', { data: { text: 'Hola' } }))
      }
    },
    INIT({ commit, dispatch }, { color, name, email }) {
      const params = getParams()
      const storageData = JSON.parse(localStorage.getItem(params.i))
      const myId = storageData?.uuid ? storageData.uuid : name || !SHOW_FORM ? uuid() : ''
      if (!myId) return
      this._vm.$socket.client.emit('user_join', { user: myId })
      commit('SET_USER', {
        id: myId,
        name: name ?? storageData?.name ?? 'Anónimo',
        email: email ?? storageData?.email
      })
      commit('SET_INSTANCE', params.i)
      if (COLOR) commit('SET_COLOR', COLOR)
      if (!storageData) {
        localStorage.setItem(
          params.i,
          JSON.stringify({
            uuid: myId,
            name: name,
            email: email
          })
        )
        dispatch('SEND_MESSAGE', { data: { text: 'Hola' } })
        setTimeout(() => dispatch('LOAD_MESSAGES', {}), 500)
      } else dispatch('LOAD_MESSAGES', {})
    }
  },
  getters: {
    MESSAGE_LIST(state) {
      return state.messages
    },
    COLORS(state) {
      return availableColors[state.options.color] ?? availableColors['blue']
    },
    PARTICIPANTS(state) {
      return state.participants
    },
    USER(state) {
      return state.options.user
    }
  }
})

function getParams() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(urlSearchParams)
}
export default store
